<template>
  <main class="main-view">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center my-5">Nenhum Aviso</h1>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
};
</script>
<style>
</style>