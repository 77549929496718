var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main-view"},[(_vm.isbreak)?_c('menu-break',{attrs:{"name":_vm.reasonsBreakSelected.label},on:{"result":_vm.onBreakClose}}):_vm._e(),(!_vm.isbreak)?_c('section',[_c('b-popover',{attrs:{"id":"popover-break-index","show":_vm.popoverShow,"target":"popover-break","triggers":"hover","placement":"top"},on:{"update:show":function($event){_vm.popoverShow=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v("Selecione a Pausa")]),_c('b-button',{staticClass:"close",attrs:{"variant":"transparent","aria-label":"Close"},on:{"click":_vm.onBreakClose}},[_c('span',{staticClass:"d-inline-block text-white pl-5",attrs:{"aria-hidden":"true"}},[_vm._v("×")])])],1)]},proxy:true}],null,false,1910757669)},[_c('b-form-group',{staticClass:"mt-1"},[_c('ul',_vm._l((_vm.reasonsBreak),function(rea,i){return _c('li',{key:i,staticClass:"cursor-pointer mb-1",on:{"click":function($event){return _vm.onBreakConfirm(rea)}}},[_vm._v(" "+_vm._s(rea.label)+" ")])}),0)])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",style:(_vm.call.status == 'Establishing'
            ? ("background: url(" + (require('@/assets/images/pages/background-dotted.png')) + ")")
            : '')},[(_vm.menuActive === 'phone')?_c('div',{staticClass:"col-12"},[_c('menu-dialer')],1):_vm._e(),(_vm.menuActive === 'filter')?_c('div',{staticClass:"col-12"},[_c('menu-filter')],1):_vm._e(),(_vm.menuActive === 'notifications')?_c('div',{staticClass:"col-12"},[_c('menu-notifications')],1):_vm._e()])]),_c('div',{staticClass:"footer fixed-bottom"},[_c('div',{staticClass:"py-1 shadow",staticStyle:{"background":"var(--light)"}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col d-flex justify-content-center"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"color":"var(--secondary)","icon":"CoffeeIcon","id":"popover-break","size":"25"}})],1),_c('div',{staticClass:"col d-flex justify-content-center",on:{"click":function($event){return _vm.setMenu('filter')}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"FilterIcon","color":"var(--secondary)","size":"25"}})],1),(!_vm.call.status && (_vm.call.number || _vm.call.number > 0))?_c('div',{staticClass:"col d-flex justify-content-center",on:{"click":_vm.onStartCall}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"success","size":"lg"}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon","size":"35"},on:{"click":_vm.onStopCall}})],1)],1):(
              _vm.call.status === 'Established' || _vm.call.status === 'Establishing'
            )?_c('div',{staticClass:"col d-flex justify-content-center",on:{"click":_vm.onStopCall}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"danger","size":"lg"}},[_c('feather-icon',{attrs:{"icon":"PhoneIcon","size":"35"},on:{"click":_vm.onStopCall}})],1)],1):_c('div',{staticClass:"col d-flex justify-content-center",on:{"click":function($event){return _vm.setMenu('phone')}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PhoneCallIcon","color":"var(--success)","size":"40"}})],1),_c('div',{staticClass:"col d-flex justify-content-center",on:{"click":function($event){return _vm.setMenu('notifications')}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"BellIcon","color":"var(--secondary)","size":"25"}})],1),_c('div',{staticClass:"col d-flex justify-content-center"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MenuIcon","color":"var(--secondary)","size":"30"},on:{"click":function () {
                  _vm.popoverShow = false;
                  _vm.isActiveOptions = true;
                }}})],1)])])])],1):_vm._e(),_c('section'),_c('b-sidebar',{attrs:{"sidebar-class":"sidebar-lg","bg-variant":"white","right":"","backdrop":"","shadow":"","no-header":""},scopedSlots:_vm._u([(_vm.isActiveOptions)?{key:"default",fn:function(ref){
                var hide = ref.hide;
return [_c('div',{staticClass:"\n          d-flex\n          justify-content-between\n          align-items-center\n          content-sidebar-header\n          px-2\n          py-1\n        "},[_c('h5',{staticClass:"mb-0"},[_vm._v("Configurações")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('menu-configuration')]}}:null],null,true),model:{value:(_vm.isActiveOptions),callback:function ($$v) {_vm.isActiveOptions=$$v},expression:"isActiveOptions"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }